.main-footer {
  align-items: center;
  background-color: var(--color-page-footer-background);
  color: var(--color-page-footer-text);
  display: flex;
  flex-direction: column;
  padding: var(--gap) var(--page-content-horizontal-padding);

  header {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    a.main-logo {
      color: inherit;
      margin-right: auto;
    }

    div {
      & * {
        font-size: var(--font-size-small);
      }
    }

    .powered-by {
      height: 40px;
      width: 120px;
    }

    @media (--phone) {
      flex-direction: column;

      a.main-logo {
        margin: 0;
      }

      .powered-by {
        margin: var(--gap-small) 0;
      }
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: var(--width-container);
    position: relative;
    width: 100%;

    @media (--desktop-) {
      flex-direction: column;
      flex-grow: 0;
      margin-left: -2rem;
    }

    & > .menu {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      flex-grow: 5;
      justify-content: space-between;

      & > ul {
        & > li {
          flex-grow: 1;
          margin: 0 0.5rem 0 0;

          @media (--tablet-) {
            margin: 0;
          }
        }
      }

      a {
        line-height: normal;
        text-decoration: none;
      }

      .menu-title {
        font-size: var(--font-size-large);
      }

      ul ul a {
        display: inline-block;
        font-size: var(--font-size-large);
        padding: 0.5rem 1rem;
      }
    }

    & > .footer-section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: var(--font-size-x-large);
      justify-content: space-between;
      line-height: normal;
      min-width: 12.68rem;

      @media (--tablet-) {
        margin: 1rem;
      }
      @media (--desktop) {
        padding: 0 1rem;
      }

      .footer-title {
        font-weight: var(--bold);
        margin-bottom: 1rem;
      }

      .social {
        display: flex;

        a {
          margin-right: 1rem;

          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }

      & > button {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: auto;
        padding: 0.5rem 2.8rem;

        @media (--tablet-) {
          align-self: center;
          margin: 0;
        }

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }

  .secondary {
    margin-top: 2rem;
  }
}

.main-header.clean-layout ~ .main-footer {
  display: none;
}
