.main-header {
  --header-background-color: var(--color-header-dark, #20273a);
  --header-color: #fff;

  align-items: center;
  background-color: var(--header-background-color);
  color: var(--header-color);
  display: flex;
  min-height: var(--header-height);
  padding: 0 var(--page-content-horizontal-padding);
  position: sticky;
  top: 0;
  z-index: 20;

  @media (--tablet-) {
    margin: 0;

    .hamburger-menu {
      &.open {
        .hamburger-menu-content {
          display: block;
          top: var(--header-height);
        }
      }
    }
  }

  &.clean-layout {
    background-color: transparent;
    color: var(--header-background-color);
    position: fixed;

    @media (--desktop) {
      background-color: transparent;
    }
    @media (--tablet-) {
      left: 0;
      right: 0;
    }
  }

  .main-logo {
    color: inherit;
    flex-shrink: 0;
    max-height: var(--header-height);
    overflow: hidden;
    position: relative;

    > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  & > .account-icon {
    display: block;
    height: 1.25rem;
    margin-left: auto;
    margin-right: var(--gap-small);
    width: 1.25rem;
    z-index: 3;
  }

  & > .mobile-account-icon {
    left: auto;
    position: relative;
    top: auto;

    &::before {
      left: calc(-1 * var(--gap) + 0.25rem);
      top: calc(-1 * var(--gap-small));
    }
  }

  .hamburger-menu-content {
    align-items: stretch;
    background-color: var(--header-background-color);
    flex-direction: column;
    font-size: var(--font-size-x-large);
    justify-content: center;
    line-height: var(--font-size-x-large);
    padding: var(--gap);

    li {
      line-height: var(--font-size-x-huge);

      & > a.active {
        color: var(--color-brand);
      }

      & > button {
        line-height: var(--font-size-x-huge);
      }
    }

    nav.main-nav {
      width: 100%;
    }

    nav.profile {
      width: 100%;
    }

    hr {
      background-color: var(--color-text-ternary);
      display: block;
      height: 1px;
      margin: var(--gap) var(--gap-tiny);
      width: auto;
    }

    .tablet-user-name {
      color: var(--color-text);
      font-size: var(--font-size-large);
      font-weight: var(--bold);
      line-height: var(--font-size-x-huge);
      max-width: 17rem;
      padding: 0 0.5rem;
      width: 100%;
    }

    .unregistered-buttons > .link,
    .menu-link,
    .menu-button {
      background-color: transparent;
      color: currentColor;
      min-height: 2rem;
      padding: 0 0.5rem;
    }
  }

  .main-nav.desktop {
    --index: 0;

    li {
      animation: bounceIn 0.2s ease-in-out;
      animation-delay: calc(0.075s * var(--index));
      animation-fill-mode: backwards;
      transition: transform ease 0.3s;
    }
  }

  nav.signed-out.desktop {
    flex-grow: 0;

    a,
    .button {
      color: inherit;
      font-size: inherit;
      font-weight: normal;

      &.flat {
        border: solid 1px var(--color-border-light);
        margin-right: var(--gap-small);

        & > a {
          padding: 0 1rem;
          text-align: center;
          width: 100%;
        }

        &::after {
          content: none;
        }
      }
    }

    .button.primary > a {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .menu-link,
    .menu-button {
      color: currentColor;
      min-height: 2rem;
      padding: 0 0.5rem;
      white-space: nowrap;
    }
  }

  nav.signed-out.mobile {
    a,
    .button {
      color: inherit;
      font-size: inherit;
      font-weight: normal;
    }
  }

  nav.menu.profile.desktop {
    flex-grow: 0;
    flex-shrink: 1;

    .profile-menu {
      display: flex;
    }

    .profile-toggle {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .label {
        align-items: center;
        color: currentColor;
        display: flex;
        flex-direction: row;
        max-width: 12.5rem;
        width: 100%;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }

      .account-icon {
        fill: currentColor;
        height: 1.5rem;
        margin-right: var(--gap-tiny);
        width: 1.5rem;
      }
    }
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.975);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes bounceOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: scale(0.75);
  }
}
