.crs-form-dialog {
  --form-width: 25rem;

  .dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 90vw;

    .crs-form {
      max-width: unset;

      & > * {
        margin-left: auto;
        margin-right: auto;

        &:not(.crs-preview-wrapper) {
          max-width: min(calc(100vw - 1.5rem * 2), var(--form-width));
        }
      }

      .crs-preview-wrapper {
        width: 100%;

        .pdf-preview {
          height: 30vh;
          width: 100%;

          &:only-child {
            height: 50vh;
          }
        }
      }
    }
  }
}

.crs-forms-wrapper {
  display: block;
  margin: 0 auto;
  width: fit-content;
}
