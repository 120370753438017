.survey-field-wrapper {
  display: flex;
  flex-direction: column;

  .survey-validation-correct-message {
    color: var(--color-success);
    font-size: var(--font-size-small);
    margin-top: 1rem;
  }

  .label-text {
    flex-wrap: wrap;

    span {
      flex-basis: 100%;
    }
  }
}
