nav.main-nav {
  overflow-x: auto;

  .menu-link,
  .menu-button {
    color: currentColor;
    min-height: 2rem;
    padding: 0 0.5rem;
    white-space: nowrap;
  }

  @media (--desktop) {
    overflow-x: auto;
  }
}
